<template>
  <div class="component-wrap">
    <div v-show="!rollingShow">
      <div class="component-tit">
        {{ $t("MyLottery") }}
        <!-- 我的抽奖 -->
      </div>
      <div class="filter-wrap" id="pageTop">
        <!-- 形式 -->
        <ListFilter
          only
          :name="$t('LotteryFormat2')"
          :allObj="{
            name: $t('CM_All'),
            id: 0,
          }"
          :dataSource="typeOpts"
          @onChange="switchType"
        />
        <!-- 状态 -->
        <ListFilter
          only
          :name="$t('CM_Status')"
          :allObj="{
            name: $t('CM_All'),
            id: 0,
          }"
          :dataSource="statusOpts"
          @onChange="switchStatus"
        >
          <template v-slot:right>
            <div class="search">
              <a-input-search
                v-model:value="lotteryName"
                :placeholder="$t('LB_Coach_EnterName')"
                style="width: 220px"
                enter-button
                allow-clear
                @search="onSearch"
              />
              <!-- 请输入名称 -->
            </div>
          </template>
        </ListFilter>
      </div>
      <a-spin :spinning="listLoading">
        <div class="content-wrap">
          <template v-if="dataList.length">
            <div class="content list">
              <div class="item" v-for="(item, index) in dataList" :key="index">
                <div class="data-card list" @click="jump(item)">
                  <div class="detail">
                    <h5>
                      <span
                        class="status bg-gray"
                        v-if="item.lotteryStatus === 1"
                      >
                        {{ $t("Pub_Tab_NotStart")
                        }}<!-- 未开始 -->
                      </span>
                      <span
                        class="status bg-green"
                        v-else-if="item.lotteryStatus === 2"
                      >
                        {{ $t("XB_UnderWay")
                        }}<!-- 进行中 -->
                      </span>
                      <span
                        class="status bg-orange"
                        v-else-if="item.lotteryStatus === 3"
                      >
                        {{ $t("XB_HasEnded")
                        }}<!-- 已结束 -->
                      </span>
                      <a-popover trigger="hover">
                        <template #content>
                          <div class="popover-content">
                            {{ item.lotteryName }}
                          </div>
                        </template>
                        {{ item.lotteryName }}
                      </a-popover>
                    </h5>
                    <div class="row">
                      <span>{{ $t("LotteryFormat") }}：</span>
                      <span class="fs12" v-if="item.lotteryType == 1">{{
                        $t("WheelOfFortune")
                      }}</span>
                      <span class="fs12" v-if="item.lotteryType == 2">{{
                        $t("RollingLottery")
                      }}</span>
                      <span class="fs12" v-if="item.lotteryType == 3">{{
                        $t("ScrapingCard")
                      }}</span>
                      <!-- 抽奖形式 -->
                    </div>
                    <div class="row">
                      <span>{{ $t("LotteryTime") }}：</span>
                      <span class="fs12"
                        >{{ dateFormat(item.startTime) }} ～
                        {{ dateFormat(item.endTime) }}</span
                      >
                      <!-- 抽奖时间 -->
                    </div>
                  </div>
                  <div class="btns">
                    <div class="btn">
                      {{ $t("view_detail") }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="page-Wrap">
              <a-pagination
                show-quick-jumper
                hideOnSinglePage
                :defaultPageSize="15"
                v-model:current="currentPage"
                :total="pageTotal"
                @change="pageChange"
              >
                <template #itemRender="{ type, originalElement }">
                  <a class="page-a" v-if="type === 'prev'">{{
                    $t("cm_pre")
                  }}</a>
                  <a class="page-a" v-else-if="type === 'next'">{{
                    $t("cm_next")
                  }}</a>
                  <renderVNode v-else :vnode="originalElement"></renderVNode>
                </template>
              </a-pagination>
            </div>
          </template>
          <a-empty
            v-if="!dataList.length && !listLoading"
            :image="require('@/assets/image/no_data_3.png')"
            :image-style="{ height: '186px' }"
            style="padding: 60px 0"
          >
            <template #description>
              <span style="color: #999">{{ $t("Pub_Lab_NoData") }}</span>
            </template>
          </a-empty>
        </div>
      </a-spin>
    </div>
    <Rolling ref="rollingRef" @back="rollingShow = false" />
  </div>
</template>

<script>
import { useI18n } from "vue-i18n";
import { reactive, toRefs, ref } from "vue";
import { useRouter } from "vue-router";
import { dateFormat } from "@/utils/tools";
import { lotteryList } from "@/api/lottery";
import ListFilter from "@/components/new/filter/ListFilter.vue";
import Rolling from "./lottery/rolling.vue";
const renderVNode = (_, { attrs: { vnode } }) => vnode;
export default {
  components: {
    ListFilter,
    renderVNode,
    Rolling,
  },
  setup() {
    const { t: $t } = useI18n();
    const router = useRouter();
    const state = reactive({
      typeOpts: [
        { id: 1, name: $t("WheelOfFortune") }, // 幸运大转盘
        { id: 2, name: $t("RollingLottery") }, // 滚动抽奖
        { id: 3, name: $t("ScrapingCard") }, // 刮刮卡
      ],
      statusOpts: [
        { id: 1, name: $t("Pub_Tab_NotStart") }, // 未开始
        { id: 2, name: $t("XB_UnderWay") }, // 进行中
        { id: 3, name: $t("XB_HasEnded") }, // 已结束
      ],
      dataList: [],
      currentPage: 1,
      pageTotal: 1,
      lotteryName: "",
      status: 0,
      lotteryType: 0,
      listLoading: true,
      rollingShow: false,
    });

    // 重新加载list
    const reloadList = () => {
      state.dataList = [];
      state.currentPage = 1;
      getList();
    };

    const pageChange = (n) => {
      state.currentPage = n;
      getList(true);
    };

    const getList = (scroll = false) => {
      state.listLoading = true;
      lotteryList({
        page: state.currentPage,
        pageSize: 15,
        name: state.lotteryName,
        status: state.status,
        lotteryType: state.lotteryType,
      }).then((res) => {
        state.listLoading = false;
        state.pageTotal = res.data.totals;
        state.dataList = res.data.list || [];
        if (scroll) {
          setTimeout(() => {
            document.getElementById("pageTop").scrollIntoView({
              behavior: "smooth",
              block: "start",
            });
          }, 0);
        }
      });
    };

    const switchStatus = (arr) => {
      state.status = arr[0];
      reloadList();
    };

    const switchType = (arr) => {
      state.lotteryType = arr[0];
      reloadList();
    };

    const onSearch = () => {
      state.currentPage = 1;
      reloadList();
    };

    const rollingRef = ref(null);

    const jump = (item) => {
      if (item.lotteryType == 1) {
        router.push({
          path: "/lottery/turntable",
          query: {
            lotteryId: item.lotteryId,
          },
        });
      } else if (item.lotteryType == 2) {
        rollingRef.value.getDetail(item.lotteryId);
        state.rollingShow = true;
      } else if (item.lotteryType == 3) {
        router.push({
          path: "/lottery/card",
          query: {
            lotteryId: item.lotteryId,
          },
        });
      }
    };

    getList();

    return {
      dateFormat,
      ...toRefs(state),
      switchStatus,
      switchType,
      pageChange,
      onSearch,
      rollingRef,
      jump,
    };
  },
};
</script>

<style lang="less" scoped>
@import "./index.less";
.data-card {
  cursor: pointer;

  .bg-green {
    background-color: #22cf33;
  }

  .bg-gray {
    background-color: #818181;
  }

  .bg-orange {
    background-color: #e8673e;
  }

  .detail {
    overflow: hidden;

    h5 {
      font-size: 16px;
      font-weight: 600;
      margin: 0 0 0 0;
      transition: all 0.3s ease;
      .mixinEllipsis(26px);

      .status {
        display: inline-block;
        line-height: 26px;
        padding: 0 6px;
        border-radius: 4px;
        font-size: 14px;
        color: #fff;
        margin-right: 12px;
        font-weight: normal;
      }
    }
  }

  &.list {
    display: block;
    position: relative;
    .mixinFlex(space-between; center);

    .detail {
      padding: 0 12px;
      width: calc(100% - 302px);

      .row {
        color: #666;
        font-size: 14px;
        line-height: 20px;
        margin: 8px 0 0 0;
        .mixinEllipsis(20px);
      }
    }

    .btns {
      width: 120px;
      .mixinFlex(flex-end; center);

      .btn {
        height: 32px;
        color: #666;
        font-weight: 500;
        font-size: 14px;
        line-height: 26px;
        padding: 2px 20px;
        text-align: center;
        border-radius: 8px;
        border: 1px solid #cacaca;
        transition: all 0.3s ease;
        user-select: none;

        &:hover {
          background-color: @color-theme;
          border: 1px solid @color-theme;
          color: #fff;
        }
      }
    }

    &:hover {
      .detail {
        h5 {
          color: #377dff;
        }
      }
    }
  }
}
</style>
