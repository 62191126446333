<template>
  <div v-if="show">
    <div class="detail" id="pageTop2">
      <div class="detail-head">
        <div class="back" @click="back">
          <ArrowLeftOutlined />
        </div>
        <div class="text">{{ detail.lotteryName }}</div>
      </div>
      <div class="detail-body">
        <div class="row">
          <div class="label">{{ $t("LotteryDescription") }}</div>
          <div class="value">{{ detail.intro }}</div>
          <!-- 抽奖说明 -->
        </div>
        <div class="row">
          <div class="label">
            {{ $t("AwardDescription")
            }}<span>
              （{{
                detail.repeatWin == 1
                  ? $t("RepeatableWinning")
                  : $t("RepeatedWinningIsNotAllowed")
              }}）
            </span>
          </div>

          <div class="prizes">
            <div
              class="prizes-item"
              v-for="(item, index) in detail.LotteryPrizeList"
              :key="index"
            >
              <div class="cover">
                <img :src="item.imgs" alt="cover" />
              </div>
              <div class="text">
                <div class="name">{{ item.prizeName }}</div>
                <div class="number">
                  {{ $t("NumberOfPrizes") }}：<span
                    >{{ item.quantity }}{{ $t("LB_My_Ge") }}</span
                  >
                </div>
              </div>
            </div>
          </div>
          <!-- 奖项说明 -->
        </div>
        <div class="row" style="padding-bottom: 0">
          <div class="label">{{ $t("WinningRecord") }}</div>
          <!-- 中奖记录 -->
        </div>
        <div class="row" v-if="!detail.winMemberList.length">
          {{ $t("YouHaventWonYet") }}
          <!-- 您暂未中奖！ -->
        </div>
        <div class="row flex" v-else>
          <div
            class="win-box"
            v-for="item in detail.winMemberList"
            :key="item.prizeId"
          >
            <div class="cover">
              <img :src="item.imgs" alt="cover" />
            </div>
            <div class="text">
              <div class="p name">{{ item.prizeName }}</div>
              <div class="p" style="margin: 25px 0 0 0; color: #666">
                {{ $t("WinningTime") }}：{{ dateFormat(item.lotteryTime) }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, toRefs } from "vue";
import { useRouter } from "vue-router";
import { dateFormat } from "@/utils/tools";
import { lotteryDetail } from "@/api/lottery";
export default {
  setup(_, { emit }) {
    const router = useRouter();
    const state = reactive({
      show: false,
      detail: {
        LotteryPrizeList: [],
        winMemberList: [],
      },
    });

    const getDetail = (lotteryId) => {
      state.show = true;
      lotteryDetail(lotteryId).then((res) => {
        if (res.ret == 0) {
          state.detail = res.data;
          setTimeout(() => {
            document.getElementById("pageTop2").scrollIntoView({
              behavior: "smooth",
              block: "start",
            });
          }, 0);
        } else {
          router.replace({
            path: `/error`,
            query: {
              title: res.msg,
              path: "/",
              name: proxy.$t("CM_Index"),
            },
          });
        }
      });
    };

    const back = () => {
      state.show = false;
      emit("back");
    };

    return {
      dateFormat,
      ...toRefs(state),
      getDetail,
      back,
    };
  },
};
</script>

<style lang="less" scoped>
.detail {
  padding-top: 16px;
  &-head {
    .mixinFlex(space-between);
    margin-bottom: 30px;
    .text {
      width: calc(100% - 30px);
      color: #333;
      font-size: 20px;
      font-weight: 400;
      line-height: 26px;
      word-break: break-all;
      text-align: center;
    }
    .back {
      color: #999;
      line-height: 26px;
      font-size: 16px;
      padding: 0 6px;
      cursor: pointer;
    }
  }
  &-body {
    .row {
      color: #333333;
      padding-bottom: 20px;
      &.flex {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
      }
      .label {
        margin-bottom: 10px;
        font-size: 16px;
        font-weight: 700;
        line-height: 26px;
        span {
          font-size: 14px;
          font-weight: normal;
        }
      }
      .value {
        margin-bottom: 10px;
        font-size: 14px;
        line-height: 24px;
      }
      .prizes {
        display: flex;
        flex-wrap: wrap;
        .prizes-item {
          width: 160px;
          height: 174px;
          background-color: #fff;
          border-radius: 4px;
          box-shadow: 0 0 10px rgba(148, 148, 148, 0.15);
          margin: 0 15px 10px 0;
          .cover {
            .mixinImgWrap(160px; 90px);
            border-radius: 4px 4px 0 0;
          }
          .text {
            padding: 6px 10px;
            .name {
              font-size: 14px;
              font-weight: 700;
              .mixinEllipsis(44px; 2);
              color: #333;
              margin-bottom: 6px;
            }
            .number {
              font-size: 14px;
              color: #333;
              span {
                font-weight: 700;
                color: #ff4e2d;
              }
            }
          }
        }
      }
      .win-box {
        width: 446px;
        margin-bottom: 12px;
        background-color: #fff;
        border-radius: 4px;
        padding: 8px;
        .mixinFlex(space-between);
        box-shadow: 0 0 10px rgba(148, 148, 148, 0.15);
        .cover {
          .mixinImgWrap(160px; 90px);
          border-radius: 4px;
        }
        .text {
          position: relative;
          width: calc(100% - 168px);
          .p {
            font-size: 14px;
            line-height: 20px;
            font-weight: 400;
            margin-bottom: 5px;
            &.name {
              .mixinEllipsis(40px; 2);
            }
            &.mb0 {
              margin-bottom: 0;
            }
          }
          .status {
            position: absolute;
            bottom: 0;
            right: 0;
            font-size: 14px;
            line-height: 20px;
            font-weight: 400;
            color: #999999;
            .mixinFlex(flex-start;center);
            .issue {
              margin-left: 12px;
            }
            .award {
              border-radius: 2px;
              padding: 0 5px;
              &.green {
                color: #22cf33;
                height: 20px;
                line-height: 18px;
                border: 1px solid #cccccc;
              }
              &.blue {
                background-color: #377dff;
                font-size: 14px;
                line-height: 20px;
                color: #fff;
                cursor: pointer;
              }
            }
          }
        }
      }
    }
  }
}
.back {
  border-radius: 4px;
}
</style>
