import request from '@/utils/request';

// 抽奖列表
export const lotteryList = params => request.post(`study/lottery/list`, params)

// 抽奖详情
export const lotteryDetail = id => request.get(`study/lottery/${id}/detail`)

// 抽奖
export const lotteryDraw = (id, params) => request.post(`study/lottery/${id}/draw`, params)

// 抽奖 开奖
export const lotteryScratch = (id, params) => request.post(`study/lottery/${id}/scratch`, params)

// 提交中奖信息
export const lotteryWinSubmit = (id, params) => request.post(`study/lottery/${id}/winsubmit`, params)